import { createContext, useContext, useState } from 'react';

const QuickSearchContext = createContext({});

export const QuickSearchProvider = ({ children }) => {
    const [showSearchDesktop, setShowSearchDesktop] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <QuickSearchContext.Provider value={{ showSearchDesktop, setShowSearchDesktop, searchTerm, setSearchTerm }}>
            {children}
        </QuickSearchContext.Provider>
    );
};

export function useQuickSearch() {
    return useContext(QuickSearchContext);
}
